<template>
  <div>
    <ed-usuario :intUsuarioId="0" :key="$root.$session.versao"/>
  </div>
</template>

<script>
import EdUsuario from "@/components/cadastro/usuario/editar";

export default {
  name: "pageCadastroUsuario",
  props: {},
  components: {EdUsuario },
  mounted() {},
  created() {},
  data() {
    return {
    };
  },
  methods: {
  
  },
};
</script>